import create from "zustand";
import { useMediaStore } from "state/media";
import { useRecordingStore } from "state/recording";
import { ModalOption, useModalStore } from "state/modal";

export const enum InterviewState {
  NotStarted,
  Started,
  Done,
}

export interface InterviewInterface {
  readonly interviewState: InterviewState;
  startInterview: () => void;
  stopInterview: () => void;
  readonly mediaRecorder?: MediaRecorder;
  readonly screenStream?: MediaStream;
  readonly videoData?: Blob;
  readonly startTime: number;
}

export const useInterviewStore = create<InterviewInterface>((set, get) => {
  // TODO polling is such a lazy way to solve this sorry
  async function lazyCameraEnablePoll() {
    return new Promise((resolve, reject) => {
      let timeout: number;
      let interval = window.setInterval(() => {
        if (useMediaStore.getState().videoStream) {
          clearTimeout(timeout);
          clearInterval(interval);
          get().startInterview();
          resolve(true);
        }
      }, 100);
      timeout = window.setTimeout(() => {
        clearInterval(interval);
        useMediaStore.getState().setCameraEnabled(false);
        alert("Unable to initialize camera");
        reject();
      }, 5000);
    });
  }

  return {
    startTime: 0,
    interviewState: InterviewState.NotStarted,
    startInterview: async () => {
      const videoStream = useMediaStore.getState().videoStream;
      if (!videoStream) {
        useMediaStore.getState().setCameraEnabled(true);
        // TODO: move to non lazy way
        await lazyCameraEnablePoll();
        return;
      }

      let screenStream: MediaStream;

      try {
        screenStream = await navigator.mediaDevices.getDisplayMedia();
      } catch (e) {
        useModalStore
          .getState()
          .setOption(ModalOption.ScreenRecordPermissionError);
        return;
      }

      screenStream.getVideoTracks()[0].addEventListener("ended", () => {
        get().stopInterview();
      });
      if (!screenStream) {
        alert("Unable to continue. Screenshare not initialized");
      }

      const combinedStream = new MediaStream([
        ...videoStream.getAudioTracks(),
        ...screenStream.getTracks(),
      ]);

      const mediaRecorder = new MediaRecorder(combinedStream);
      mediaRecorder.start();
      mediaRecorder.addEventListener("dataavailable", (e) => {
        set({ videoData: e.data, interviewState: InterviewState.Done });
        useRecordingStore.getState().addVideo(e.data);
      });

      const interviewState = InterviewState.Started;
      set({
        interviewState,
        mediaRecorder,
        startTime: Date.now(),
        screenStream,
      });
    },
    stopInterview: () => {
      const { mediaRecorder, screenStream } = get();
      if (screenStream) {
        screenStream.getTracks().forEach((t) => t.stop());
      }

      if (mediaRecorder) {
        mediaRecorder.stop();
      }
      useMediaStore.getState().setCameraEnabled(false);
      set({ interviewState: InterviewState.Done });
    },
  };
});
