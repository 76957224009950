import { useRecordingStore } from "state/recording";
import { Recording } from "db";
import { useMemo } from "react";
import styles from "./Videos.module.scss";
import { Button } from "ui/Button/Button";
import { formatDistanceToNow } from "date-fns";

function Video({
  onRemove,
  recording,
}: {
  onRemove: () => void;
  recording: Recording;
}) {
  const { name, blob, createdAt } = recording;
  const url = useMemo(() => URL.createObjectURL(blob), [blob]);
  return (
    <div className={styles.video}>
      <h3>{name}</h3>
      {createdAt && <p>{formatDistanceToNow(createdAt)} ago</p>}
      <video src={url} controls />
      <Button heedful onClick={onRemove}>
        X
      </Button>
    </div>
  );
}

export function Videos() {
  const { videos, removeVideo } = useRecordingStore((state) => state);
  return (
    <div className={styles.container}>
      <h2>Your recordings</h2>
      <div className={styles.videos}>
        {videos.map((rec, i) => (
          <Video key={i} onRemove={() => removeVideo(rec.id)} recording={rec} />
        ))}
      </div>
    </div>
  );
}
