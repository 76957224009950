import styles from "./Footer.module.scss";

export function Footer() {
  return (
    <div className={styles.container}>
      Would you like to know about updates?{" "}
      <form
        action="https://icloud.us11.list-manage.com/subscribe/post"
        method="POST"
      >
        <div
          className={styles.hide}
          aria-label="Please leave the following three fields empty"
          aria-hidden="true"
        >
          <label htmlFor="b_name">Name: </label>
          <input
            type="text"
            name="b_name"
            tabIndex={-1}
            value=""
            placeholder="Freddie"
            id="b_name"
          />

          <label htmlFor="b_email">Email: </label>
          <input
            type="email"
            name="b_email"
            tabIndex={-1}
            value=""
            placeholder="youremail@gmail.com"
            id="b_email"
          />

          <label htmlFor="b_comment">Comment: </label>
          <textarea
            name="b_comment"
            tabIndex={-1}
            placeholder="Please comment"
            id="b_comment"
          />
        </div>
        <input type="hidden" name="u" value="1da9c313bb9460720b7ba2730" />
        <input type="hidden" name="id" value="95d8bfa923" />
        <input type="email" name="MERGE0" />
        <input type="submit" value="Subscribe" />
      </form>
    </div>
  );
}
