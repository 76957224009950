import { Button } from "ui/Button/Button";
import { useInterviewStore } from "state/interview";
import styles from "./GetStarted.module.scss";

export function GetStarted() {
  const startInterview = useInterviewStore((state) => state.startInterview);
  return (
    <div className={styles.content}>
      <h3>
        This tool was made to help software engineers practice their interview
        skills.
      </h3>
      <h4>A new coding challenge will be revealed each day.</h4>
      <p>
        You can select any language you would like but we do not allow you to
        run the code. This is the best way to practice as not all companies let
        you run code.
      </p>
      <p>
        When you click Start Interview this app will begin to record yourself
        solving an interview coding challenge. Doing this will allow you to
        evaluate your coding and communication skills. Your video will not be
        uploaded anywhere, it is for your own personal use.
      </p>
      <div className={styles.buttonWrapper}>
        <Button large onClick={startInterview}>
          Start Interview
        </Button>
      </div>
    </div>
  );
}
