import { LanguageStore, useLanguageStore } from "state/language";
import React, { Suspense, useEffect, useState } from "react";
import styles from "./Interview.module.scss";
const Editor = React.lazy(() => import("@monaco-editor/react"));
const Whiteboard = React.lazy(() => import("ui/Whiteboard/Whiteboard"));

const getLanguageSelector = (state: LanguageStore) => state.languageId;
const useLanguage = () => useLanguageStore(getLanguageSelector);

export function Interview({ whiteboard }: { whiteboard?: boolean }) {
  const [code, setCode] = useState<string | undefined>("");

  useEffect(() => {
    delete window.sessionStorage["wb-data"];
  }, []);

  return (
    <div className={styles.container}>
      <Suspense fallback={<div>Loading…</div>}>
        {whiteboard ? <Whiteboard /> : <Code code={code} setCode={setCode} />}
      </Suspense>
    </div>
  );
}

function Code({
  code,
  setCode,
}: {
  code?: string;
  setCode: (code?: string) => void;
}) {
  const language = useLanguage();
  return (
    <Editor
      value={code}
      onChange={setCode}
      onMount={(e) => e.focus()}
      options={{ fontSize: 16 }}
      language={language}
      theme="vs-dark"
    />
  );
}
