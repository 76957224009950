export function Check() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="6.5" cy="6.5" r="6.5" fill="#D9D9D9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.81607 8.9045L2.77061 6.85905L3.61285 6.01681L5.65831 8.06226L9.74919 3.97138L10.5914 4.81362L5.65831 9.74674L5.53798 9.62641L4.81607 8.9045Z"
        fill="black"
      />
    </svg>
  );
}
