import clsx from "clsx";
import styles from "./RightPanel.module.scss";
import { useNavigate } from "react-router-dom";
import { Button } from "ui/Button/Button";
import { LanguageSelector } from "ui/LanguageSelector/LanguageSelector";
import { CountdownTimer } from "ui/CountdownTimer/CountdownTimer";
import { GetStarted } from "ui/GetStarted/GetStarted";
import { InterviewInterface, useInterviewStore } from "state/interview";
import { Interview } from "ui/Interview/Interview";
import { useIsStarted } from "hooks/interview";

const stopInterviewSelector = (state: InterviewInterface) =>
  state.stopInterview;
const useStopInterview = () => useInterviewStore(stopInterviewSelector);

export function RightPanel({ whiteboard }: { whiteboard?: boolean }) {
  const isStarted = useIsStarted();
  const stopInterview = useStopInterview();
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.titleBar}>
        <div className={styles.tabs}>
          <div className={clsx({ [styles.active]: isStarted && !whiteboard })}>
            <span onClick={() => navigate("/")}>Code:</span>
            <LanguageSelector />
          </div>
          {isStarted && (
            <div
              onClick={() => isStarted && navigate("/whiteboard")}
              className={clsx({ [styles.active]: isStarted && whiteboard })}
            >
              Whiteboard
            </div>
          )}
        </div>
        {isStarted && (
          <>
            <CountdownTimer />
            <Button
              heedful
              onClick={() => {
                stopInterview();
                navigate("/videos", { replace: true });
              }}
            >
              Stop Interview
            </Button>
          </>
        )}
      </div>
      {isStarted ? <Interview whiteboard={whiteboard} /> : <GetStarted />}
    </div>
  );
}
