import create from 'zustand';

export interface LanguageStore {
  readonly languageId: string;
  setLanguageId(language: string): void;
}

const LANG_ID_LOCAL_KEY = 'language-id';

export const useLanguageStore = create<LanguageStore>(set => ({
  languageId: localStorage[LANG_ID_LOCAL_KEY] || 'javascript',
  setLanguageId: languageId => {
    localStorage[LANG_ID_LOCAL_KEY] = languageId;
    set({languageId});
  },
}));
