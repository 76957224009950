import { useNavigate } from "react-router-dom";
import Logo from "images/Logo.svg";
import styles from "./Header.module.scss";
import { Button } from "ui/Button/Button";
import { useRecordingStore } from "state/recording";

export function Header() {
  const { videos } = useRecordingStore((state) => state);
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div>
        <img
          onClick={() => navigate("/")}
          src={Logo}
          alt="Watch Me - Help Me logo"
        />
        <Button to="/">Mock Interview</Button>
        {videos.length > 0 && <Button to="videos">Your Videos</Button>}
      </div>
      <div className={styles.title}>
        Practice solving software engineer interview questions. New question
        posted daily.
      </div>
    </div>
  );
}
