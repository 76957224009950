export function Dots() {
  return (
    <svg
      width="39"
      height="25"
      viewBox="0 0 39 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_7_111)">
        <circle cx="28.0562" cy="11.1509" r="3.15088" fill="white" />
        <circle cx="18.6035" cy="11.1509" r="3.15088" fill="white" />
        <circle cx="9.15088" cy="11.1509" r="3.15088" fill="white" />
      </g>
      <defs>
        <filter
          id="filter0_d_7_111"
          x="6"
          y="8"
          width="27.2071"
          height="8.30176"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7_111"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_7_111"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
