import {languages as monacoLanguages} from 'monaco-editor';
import {Down} from 'images/Down';
import {Dropdown} from 'ui/Dropdown/Dropdown';
import {useLanguageStore} from 'state/language';
import styles from './LanguageSelector.module.scss';

const languages = monacoLanguages
  .getLanguages()
  .map(l => ({id: l.id, value: l.id}));

export function LanguageSelector() {
  const [languageId, setLanguageId] = useLanguageStore(state => [
    state.languageId,
    state.setLanguageId,
  ]);
  return (
    <Dropdown
      options={languages}
      defaultOption={{
        id: languageId || 'javascript',
        value: languageId || 'javascript',
      }}
      onSelectedItemChange={v => {
        v && setLanguageId(v.id);
      }}>
      {selectedItem => (
        <div className={styles.label}>
          {selectedItem ? selectedItem.value : 'Select Language'}
          <Down className={styles.down} />
        </div>
      )}
    </Dropdown>
  );
}
