import styles from './Button.module.scss';
import {Link} from 'react-router-dom';
import clsx from 'clsx';

export function Button({
  children,
  large,
  onClick,
  heedful,
  to,
}: {
  children: React.ReactNode;
  large?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  heedful?: boolean;
  to?: string;
}) {
  const btn = (
    <button
      onClick={onClick}
      className={clsx(styles.button, {
        [styles.large]: large,
        [styles.heedful]: heedful,
      })}>
      {children}
    </button>
  );

  if (to) {
    return <Link to={to}>{btn}</Link>;
  }

  return btn;
}
