import create from "zustand";

export const enum ModalOption {
  None,
  ScreenRecordPermissionError,
}

export interface ModalState {
  setOption: (option: ModalOption) => void;
  modalOption: ModalOption;
}

export const useModalStore = create<ModalState>((set) => ({
  modalOption: ModalOption.None,
  setOption: (modalOption) => set({ modalOption }),
}));

// @ts-ignore
window.useModalStore = useModalStore;
