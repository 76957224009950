import {Dropdown, DropdownItem} from 'ui/Dropdown/Dropdown';
import {useEffect, useState} from 'react';
import {Dots} from 'images/Dots';
import styles from './CameraSelector.module.scss';
import {useMediaStore} from 'state/media';

export function CameraSelector() {
  const [options, setOptions] = useState<DropdownItem[]>([]);
  const [videoInputId, setVideoInput] = useMediaStore(state => [
    state.videoInputId,
    state.setVideoInput,
  ]);
  useEffect(() => {
    (async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      setOptions(
        devices
          .filter(k => k.kind === 'videoinput')
          .map(o => ({id: o.deviceId, value: o.label})),
      );
    })();
  }, []);
  if (options.length === 0) {
    return null;
  }

  const defaultOption = options.find(o => o.id === videoInputId);

  return (
    <div className={styles.container}>
      <Dropdown
        defaultOption={defaultOption}
        options={options}
        onSelectedItemChange={v => {
          v ? setVideoInput(v.id) : setVideoInput(null);
        }}>
        {() => <Dots />}
      </Dropdown>
    </div>
  );
}
