import styles from "ui/LeftPanel/LeftPanel.module.scss";
import { SelfieCam } from "ui/SelfieCam/SelfieCam";
import { Challenge } from "ui/Challenge/Challenge";

export function LeftPanel() {
  return (
    <div className={styles.container}>
      <SelfieCam />
      <hr />
      <Challenge />
    </div>
  );
}
