import {useEffect, useState} from 'react';
import {
  useInterviewStore,
  InterviewInterface,
  InterviewState,
} from 'state/interview';
import styles from './CountdownTimer.module.scss';

const startTimeSelector = (state: InterviewInterface) => [
  state.startTime,
  state.interviewState,
];
const useStartTime = () => useInterviewStore(startTimeSelector);

export function CountdownTimer() {
  const [seconds, setSeconds] = useState(0);

  const [startTime, state] = useStartTime();

  let m = 0;
  let s = 0;

  useEffect(
    () => {
      let i = setInterval(() => {
        if (state === InterviewState.Started) {
          setSeconds(Math.round((Date.now() - startTime) / 1000));
        } else {
          setSeconds(0);
        }
      }, 1000);

      return () => {
        clearInterval(i);
      };
    },
    [setSeconds, startTime, state],
  );

  if (startTime > 0) {
    m = Math.floor(seconds / 60);
    s = seconds % 60;
  }

  return (
    <div className={styles.container}>
      Total Time:{' '}
      <span className={styles.time}>
        {m}m {s}s
      </span>
    </div>
  );
}
