import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import { Header } from "ui/Header/Header";
import { Main } from "ui/Main/Main";
import { Videos } from "ui/Videos/Videos";
import { Footer } from "ui/Footer/Footer";
import { Modal } from "ui/Modal/Modal";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Modal />
        <Header />
        <Routes>
          <Route path="/videos" element={<Videos />} />
          <Route path="/whiteboard" element={<Main whiteboard />} />
          <Route path="/" element={<Main />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
