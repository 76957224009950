import styles from "./Main.module.scss";
import { LeftPanel } from "ui/LeftPanel/LeftPanel";
import { RightPanel } from "ui/RightPanel/RightPanel";

export function Main({ whiteboard }: { whiteboard?: boolean }) {
  return (
    <div className={styles.container}>
      <LeftPanel />
      <RightPanel whiteboard={whiteboard} />
    </div>
  );
}
