import create from "zustand";
import { Recording, db } from "db";
import { useChallengeStore } from "state/challenge";

export interface RecordingStore {
  readonly videos: Recording[];
  addVideo: (blob: Blob) => void;
  removeVideo: (id?: number) => void;
}

export const useRecordingStore = create<RecordingStore>((set) => {
  if (db.recordings) {
    const d = db.recordings.orderBy("createdAt").reverse();
    (async () => {
      const videos = (await d.toArray()).map((v) => v);
      set({ videos });
    })();
  }
  return {
    videos: [],
    addVideo: async (blob) => {
      if (db.recordings) {
        const name = useChallengeStore.getState().name;
        const createdAt = Date.now();
        const id = await db.recordings.add({ name, createdAt, blob });
        const video = { id, name, createdAt, blob } as Recording;
        set((state) => ({ videos: [video, ...state.videos] }));
      }
    },
    removeVideo: async (id) => {
      if (!db.recordings || !id) {
        return;
      }

      if (!window.confirm("Are you sure you want to delete this video?")) {
        return;
      }

      await db.recordings.delete(id);
      set((state) => {
        const videos = state.videos.filter((v) => v.id !== id);
        return { videos };
      });
    },
  };
});
