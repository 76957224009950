import React from "react";
import styles from "./Challenge.module.scss";
import { Fence } from "ui/Fence";
import Markdoc from "@markdoc/markdoc";
import { useChallenge } from "hooks/useChallenge";
import { useIsStarted } from "hooks/interview";

function hide(input: string) {
  return input.replace(/[\w.,]/g, "█");
}

export function Challenge() {
  const started = useIsStarted();
  const challenge = useChallenge();
  return (
    <div className={styles.container}>
      {!started && (
        <>
          Click <strong>Start Interview</strong> to reveal today's coding
          challenge
        </>
      )}
      {started && (
        <div className={styles.challenge}>
          {Markdoc.renderers.react(challenge, React, { components: { Fence } })}
        </div>
      )}
      <p>
        {!started &&
          hide(
            `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut suscipit
        elementum odio, in maximus felis mattis eget. Suspendisse rutrum commodo
        dignissim. Morbi efficitur eu ligula eget condimentum. Proin velit
        mauris, luctus et massa ut, ultrices porttitor orci. Integer posuere sem
        quis velit varius laoreet. Nullam a neque sem. Vestibulum sed libero id
        dolor cursus blandit id quis quam. Nunc sed aliquam velit, id vestibulum
        odio. Fusce a tincidunt elit.`
          )}
      </p>
    </div>
  );
}
