import create from 'zustand';

interface MediaStore {
  readonly videoInputId: string | null | undefined;
  setVideoInput(videoInputId: string | null | undefined): void;
  readonly videoStream?: MediaStream;
  setVideoStream: (videoStream: MediaStream) => void;
  readonly cameraEnabled: boolean;
  setCameraEnabled: (cameraEnabled: boolean) => void;
}

const VID_ID_LOCAL_KEY = 'video-input-id';

export const useMediaStore = create<MediaStore>((set, get) => ({
  videoInputId: localStorage[VID_ID_LOCAL_KEY] || '',
  setVideoInput: videoInputId => {
    localStorage[VID_ID_LOCAL_KEY] = videoInputId;
    set({videoInputId});
  },
  setVideoStream: videoStream => {
    set({videoStream});
  },
  cameraEnabled: false,
  setCameraEnabled: cameraEnabled => {
    set({cameraEnabled});
    if (!cameraEnabled) {
      const videoStream = get().videoStream;
      if (videoStream) {
        videoStream.getTracks().forEach(t => t.stop());
        set({videoStream: undefined});
      }
    }
  },
}));
