import React, {useEffect, useRef} from 'react';
import Prism from 'prismjs';

export function Fence({
  children,
  language,
}: {
  children: React.ReactElement;
  language: string;
}) {
  const preRef = useRef<HTMLPreElement>(null);

  useEffect(() => {
    if (preRef.current) {
      Prism.highlightElement(preRef.current);
    }
  }, []);

  return (
    <pre ref={preRef} key={language} className={`language-${language}`}>
      {children}
    </pre>
  );
}
