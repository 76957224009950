import clsx from 'clsx';
import styles from './Dropdown.module.scss';
import {useSelect} from 'downshift';
import {Check} from 'images/Check';

export interface DropdownItem {
  id: string;
  value: string;
}

export function Dropdown({
  options,
  defaultOption,
  children,
  onSelectedItemChange,
}: {
  options: DropdownItem[];
  defaultOption?: DropdownItem;
  children: (selectedItem?: DropdownItem | null) => React.ReactNode;
  onSelectedItemChange?: (value?: DropdownItem | null) => void;
}) {
  const {
    isOpen,
    getToggleButtonProps,
    selectedItem,
    getMenuProps,
    getItemProps,
    highlightedIndex,
  } = useSelect({
    initialSelectedItem: defaultOption || options[0],
    items: options,
    onSelectedItemChange: v => {
      onSelectedItemChange && onSelectedItemChange(v.selectedItem);
    },
  });
  return (
    <span className={styles.container}>
      <span {...getToggleButtonProps()}>{children(selectedItem)}</span>
      <div {...getMenuProps()}>
        {isOpen && (
          <ul className={styles.menu}>
            {options.map((item, index) => (
              <li
                key={`${index}-${item}`}
                className={clsx({
                  [styles.hover]: index === highlightedIndex,
                })}
                {...getItemProps({item, index})}>
                {selectedItem && selectedItem.id === item.id ? (
                  <Check />
                ) : (
                  <span className={styles.spacer} />
                )}
                {item.value}
              </li>
            ))}
          </ul>
        )}
      </div>
    </span>
  );
}
