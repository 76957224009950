import { ModalOption, useModalStore } from "state/modal";
import { Button } from "ui/Button/Button";
import styles from "./Modal.module.scss";

function DismissModal() {
  const setOption = useModalStore((state) => state.setOption);
  return <Button onClick={() => setOption(ModalOption.None)}>Close</Button>;
}

const modals = {
  [ModalOption.ScreenRecordPermissionError]: (
    <>
      <strong>Cannot share your screen</strong>
      <p>
        Your browser might have screen-recording permission on your computer
      </p>
      <h3>Try again but only share your tab rather than the entire screen.</h3>
      <p>
        Otheriwse you will have to go to{" "}
        <a
          target="_blank"
          href="x-apple.systempreferences:com.apple.preference.security?Privacy_ScreenCapture"
        >
          System Preferences
        </a>{" "}
        and follow the instructions{" "}
        <a
          target="help"
          href="https://support.apple.com/guide/mac-help/control-access-to-screen-recording-on-mac-mchld6aa7d23/mac"
        >
          here
        </a>.
      </p>
      <DismissModal />
    </>
  ),
};

export function Modal() {
  const modalOption = useModalStore((state) => state.modalOption);
  if (modalOption === ModalOption.None) {
    return null;
  }

  return (
    <div className={styles.backdrop}>
      <div className={styles.modal}>
        {modals[modalOption] || "There was an error"}
      </div>
    </div>
  );
}
