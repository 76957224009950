import styles from './SelfieCam.module.scss';
import {useEffect, useRef} from 'react';
import {CameraSelector} from 'ui/CameraSelector/CameraSelector';
import {useMediaStore} from 'state/media';

function useCamera(): [
  boolean,
  (enabled: boolean) => void,
  MediaStream | undefined
] {
  const [
    videoInputId,
    videoStream,
    setVideoStream,
    cameraEnabled,
    setCameraEnabled,
  ] = useMediaStore(state => [
    state.videoInputId,
    state.videoStream,
    state.setVideoStream,
    state.cameraEnabled,
    state.setCameraEnabled,
  ]);
  useEffect(
    () => {
      if (cameraEnabled) {
        // TODO this shouldn't live here. Prob move to store
        (async () => {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: {
              deviceId: videoInputId || '',
            },
            audio: true,
          });
          setVideoStream(stream);
        })();
      }
    },
    [cameraEnabled, videoInputId, setVideoStream],
  );

  return [cameraEnabled, setCameraEnabled, videoStream];
}

export function SelfieCam() {
  const [cameraEnabled, setCameraEnabled, stream] = useCamera();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(
    () => {
      if (videoRef.current && stream) {
        videoRef.current.srcObject = stream;
      }
    },
    [stream],
  );

  if (!cameraEnabled) {
    return (
      <div onClick={() => setCameraEnabled(true)} className={styles.container}>
        Click Here To Enable Your Camera
      </div>
    );
  }
  if (!stream) {
    return <div className={styles.container}>Loading…</div>;
  }

  return (
    <div className={styles.container}>
      <CameraSelector />
      <video
        muted={true}
        autoPlay={true}
        ref={videoRef}
        className={styles.video}
      />
    </div>
  );
}
