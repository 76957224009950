export function Down({className}: {className?: string}) {
  return (
    <svg
      className={className}
      width="9"
      height="6"
      viewBox="0 0 9 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M-1.71143e-08 0.250031L8.50003 0.25003L4.25002 5.50005L-1.71143e-08 0.250031Z"
        fill="white"
      />
    </svg>
  );
}
