import { useEffect, useState } from "react";
import { ChallengeState, useChallengeStore } from "state/challenge";

const challengeNameSelector = (state: ChallengeState) => state.name;

export function useChallenge() {
  const name = useChallengeStore(challengeNameSelector);
  const [challenge, setChallenge] = useState("");

  useEffect(
    () => {
      (async () => {
        const data = await import(`challenges/${name}.md`);
        setChallenge(data.default);
      })();
    },
    [name]
  );

  return challenge;
}
