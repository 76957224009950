import create from "zustand";
import challenges from "challenges/data.json";

const ONE_DAY_MS = 1000 * 60 * 60 * 24;
const FIRST_DAY_OF_YEAR = new Date(new Date().getFullYear(), 0, 1).getTime();
const TOTAL_CHALLENGES = challenges.length;

export interface ChallengeState {
  name?: string;
}

export const useChallengeStore = create<ChallengeState>(() => {
  const daysThisYear = Math.floor(
    (Date.now() - FIRST_DAY_OF_YEAR) / ONE_DAY_MS
  );
  let index = daysThisYear;
  if (index > TOTAL_CHALLENGES) {
    index = daysThisYear % TOTAL_CHALLENGES;
  }

  return {
    name: challenges[index],
  };
});
