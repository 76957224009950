import Dexie, { Table } from "dexie";

export interface Recording {
  id?: number;
  name?: string;
  createdAt?: number;
  blob: Blob;
}

export class RecordingDB extends Dexie {
  recordings?: Table<Recording>;

  constructor() {
    super("recordingDb");
    this.version(1).stores({
      recordings: "++id, name, createdAt, blob",
    });
  }
}

export const db = new RecordingDB();
